// store.js
import { createStore } from 'vuex';
export default createStore({
  state: {
    // baseUrl: 'http://127.0.0.1:8000',
    // baseUrl :'http://14.248.85.158:8018',
    // baseUrl :'http://65.20.79.158:8088',
    baseUrl: 'https://api.vanmieunighttour.com',
    apiKey: 'A3bC1dE5F7gH9iJ0kLmN2oP4qR6sT8uVWxYzZ',
    alert: {
      status: false,
      mess:'',
    },
    load:true,
    loading:{
      status:false
    },
    valuePage: {
      count: 0,
      hasPages: null,
      current_page: 1,
      data: null,
      total: null,
      path: null,
      prev_page_url: null,
      per_page: 0,
      to: 5,
      from: null,
      first_page_url: null,
      last_page_url: null,
      next_page_url: null,
      onFirstPage: null,
      hasMorePages: null,
      links: null,
    }
  },
  mutations: {
    updateValuePage(state, data) {
      state.store = { ...state.store, ...data };
    },
    setPermission(state, data) {
      state.permission = data;
    },
    checkPermission(permission){
      return state.permission.includes(permission);
    }
  },
  actions: {
    fetchDataFromApi({ commit }) {

    },
  },
});

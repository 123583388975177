// router/middleware/auth.js
import store from '/src/store/index.js';

export function requireAuth(to, from, next) {
  const userInfoString = sessionStorage.getItem('userInfo');
  if (userInfoString) {
    var myHeaders = new Headers();
    const userInfo = JSON.parse(userInfoString);
    myHeaders.append('Authorization', 'Bearer ' + userInfo['access_token']);
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };
    fetch(store.state.baseUrl + '/api/auth/user-profile', requestOptions)
      .then((response) => {
        if (response.status === 200) {
          next();
        } else if (response.status === 401) {
          // refreshToken chỉ khi response.status === 401
          refreshToken()
            .then(() => {
              next();
            })
            .catch(() => {
              next(); // Xử lý lỗi khác nếu có
            });
        } else {
          next(); // Xử lý lỗi khác nếu có
        }
      })
      .catch((error) => {
        console.error(error);
        next(error); // Xử lý lỗi khác nếu có
      });
  } else {
    next('/login');
  }
}

export function refreshToken() {
  const valueUserInfoString = sessionStorage.getItem('userInfo');
  const valueUserInfo = JSON.parse(valueUserInfoString);
  if (valueUserInfo) {
    var myHeaders = new Headers();
myHeaders.append('Authorization', 'Bearer ' + valueUserInfo.access_token);
    var formdata = new FormData();

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    };

    return fetch(store.state.baseUrl + '/api/auth/refreshToken', requestOptions)
      .then(response => response.json())
      .then(result => {
        const currentTime = new Date();
        const newTime = new Date(currentTime.getTime() + 5 * 60000); // 5 phút = 5 * 60 giây = 5 * 60,000 milliseconds
        const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
        userInfo.access_token = result[0].access_token;
        userInfo.expires_in = newTime;

        sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
      })
      .catch(error => console.log('error', error));
  }
}

import { createApp } from 'vue'
import App from './App.vue'
import './assets/taiwind.css'
import router from './router'
import store from '@/store/index.js';
import { initFlowbite } from 'flowbite'


const app = createApp(App)
 
app.use(initFlowbite)
app.use(router)
app.use(store);
app.mount('#app')